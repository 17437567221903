<template>
  <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">

    <vx-card title="แก้ไข Ranking">
      <vs-table :data="infoRank">
        <template slot="thead">
          <vs-th>
            ลำดับ
          </vs-th>
          <vs-th>
            ระดับ
          </vs-th>
          <vs-th>
            เล่นครบ
          </vs-th>
          <vs-th>
            รับเครดิตฟรี
          </vs-th>
          <vs-th>
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].id">
              {{data[indextr].ranking_id}}
            </vs-td>

            <vs-td :data="data[indextr].name">
              {{data[indextr].ranking_name}}
            </vs-td>

            <vs-td :data="data[indextr].name">
              {{currency(data[indextr].ranking_min_sum_turnranking)}}
            </vs-td>

            <vs-td :data="data[indextr].name">
              {{currency(data[indextr].ranking_credit)}}
            </vs-td>

            <vs-td :data="data[indextr].page">
              <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" type="border" size="small" icon-pack="feather" icon="icon-edit" class="mr-2"
                @click="edit(data[indextr].ranking_id)">แก้ไข</vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <vs-popup classContent="popup-example" :title="infopopup.name" :active.sync="showpopup">
      <vs-input class="w-full" label-placeholder="เล่นครบ" v-model="infopopup.turn" type = "number" name="Newturn" />
      <br>
      <vs-input class="w-full" label-placeholder="รับเครดิตฟรี" v-model="infopopup.credit" type = "number" name="Newcredit" />
      <br>
       <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true"  size="small" icon-pack="feather" icon="icon-edit" class="mr-2"
                @click="confirm_edit()">ยืนยัน</vs-button>
    </vs-popup>
  </div>
</template>

<script>
import axios from '../../axios'
import log from '../../log'
export default {

  data () {
    return {
      infoRank: '',
      showpopup: false,
      infopopup: {
        name: '',
        credit: '',
        turn: ''
      },
      error : ''
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  mounted () {
    this.callinfo()
  },
  methods: {
    callinfo () {
      axios
        .get('/Rank/info')
        .then(response => (this.infoRank = response.data))
    },
    currency (amount) {
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        return `${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }

    },
    edit (id) {
      const i = parseInt(id - 1)
      this.infopopup.name = this.infoRank[i].ranking_name
      this.infopopup.credit = this.infoRank[i].ranking_credit
      this.infopopup.turn = this.infoRank[i].ranking_min_sum_turnranking
      this.showpopup = true
    },
    async confirm_edit () {
      await axios.post('/Rank/editinfo', {
        name: this.infopopup.name,
        turn: this.infopopup.turn,
        credit: this.infopopup.credit
      })
        .then(response => (this.error = response.data))
      if (this.error.status === true) {
        await log.agent('', 'EditRank', 0, 'แก้ไขข้อมูล Ranking')
        this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          text: this.error.info,
          title : 'ทำรายการสำเร็จ'
        })
        this.callinfo()
        this.showpopup = false
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          text: this.error.info,
          title : 'ทำรายการผิดพลาด'
        })

      }
    }
  }
}

</script>

<style lang="scss">
  #profile-tabs {
    .vs-tabs--content {
      padding: 0;
    }
  }

</style>
